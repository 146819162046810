<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="@/assets/image/logo.png"
          class="my-3"
          contain
          height="200"
        />
        <div class="text-h6 font-weight-light text--primary">
          <p>
            Bạn đang thấy có gì đó không phù hợp, đang gặp vấn đề. Gửi thông báo
            cho chúng tôi ở bên dưới này hoặc gửi email
            <a href="mailto:admin@ptamda.com">admin@ptamda.com</a> để chúng tôi
            có thể hiểu rõ hơn về vấn đề này
          </p>
        </div>
        <div class="text-h6 font-weight-light text--primary">
          <v-text-field
            label="Tiêu đề - Mô tả ngắn"
            :rules="rules"
            v-model="title"
            @keyup.enter="reportError()"
          />
          <v-text-field
            label="Số điện thoại hoặc email để tiện liên lạc với bạn"
            v-model="contact"
            @keyup.enter="reportError()"
          />
          <v-textarea
            v-model="content"
            auto-grow
            :rules="rules"
            label="Nội dung - chi tiết góp ý vẫn đề"
            row-height="20"
          />

          <v-btn
            color="secondary"
            class="ma-2"
            @click="reportError()"
            :loading="loading"
            :disabled="loading"
          >
            Gửi
            <v-icon right>mdi-send</v-icon>
          </v-btn>
        </div>

        <v-divider class="my-5" />

        <v-btn color="error" small @click="clearData()">
          Làm mới ứng dụng
          <v-icon right>mdi-eye-refresh-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { reportError } from '@/helper/user'
export default {
  data() {
    let reportConfigCom = localStorage.getItem("reportConfigCom");
    if (reportConfigCom) {
      reportConfigCom = JSON.parse(reportConfigCom);
    } else {
      reportConfigCom = {
        title: "",
        contact: "",
        content: "",
      };
    }

    return {
      rules: [(value) => !!value || "Vui lòng không để trống."],
      loading: false,

      title: reportConfigCom.title,
      contact: reportConfigCom.contact,
      content: reportConfigCom.content,
    };
  },
  methods: {
    reportError() {
      if (this.title && this.content) {
        reportError({
          data: {
            title: this.title,
            contact: this.contact,
            detail: this.content,
          },
          done: data => {
            this.title = this.contact = this.content = ''
            this.$emit('closeDialogLogin')
            this.loading = false
          },
          error: res => {
            this.loading = false
            console.log('Đã có lỗi xảy ra', res)
          }
        })
      }
    },
    changeValue() {
      localStorage.setItem(
        "reportConfigCom",
        JSON.stringify({
          title: this.title,
          contact: this.contact,
          content: this.content
        })
      );
    },
    clearData() {
      if (confirm("Bạn có chắc chắn muốn làm mới? Tính năng này sẽ xóa toàn bộ dữ liệu ứng dụng và bạn có thể sẽ phải kết nối, đăng nhập lại.")) {
        global.localStorage.clear();
        window.location.href = '/'
      }
    },
  },
  watch: {
    title() {
      this.changeValue();
    },
    contact() {
      this.changeValue();
    },
    content() {
      this.changeValue();
    },
  }
};
</script>
